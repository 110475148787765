<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    :width="dialogWith + '%'"
    @close="cancalFun()"
  >
    <div class="gps-form">
      <el-form
        ref="ruleFormRef"
        :show-message="false"
        :model="ruleForm"
        :rules="rules"
        status-icon
        label-width="90px"
      >
        <el-row :gutter="10" v-for="(rr, ir) in ruleForm_temp" :key="'r' + ir">
          <el-col
            :span="24 / rr.length"
            :offset="0"
            v-for="it in rr"
            :key="'i' + it.field"
            ><el-form-item
              :label="it.label"
              :prop="it.field"
              v-if="it.type != 'empty'"
            >
              <div class="flex-row" v-if="it.type == 'ipt_ipts'">
                <el-input
                  v-for="(ipit, ip_i) in ruleForm[it.field]"
                  :key="ip_i"
                  clearable
                  :placeholder="ruleForm[it.field][ip_i]"
                  v-model="ruleForm[it.field][ip_i]"
                />
              </div>
              <el-tree-select
                v-if="it.type == 'tsel'"
                v-model="ruleForm[it.field]"
                :data="it.data"
                filterable
              />
              <el-input
                v-if="it.type == 'ipt'"
                clearable
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
              />
              <el-input
                v-if="it.type == 'pwd'"
                clearable
                type="password"
                show-password
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'dtr'"
                v-model="ruleForm[it.field]"
                type="datetimerange"
                range-separator="To"
                :start-placeholder="it.title_s"
                :end-placeholder="it.title_e"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'date'"
                v-model="ruleForm[it.field]"
                type="date"
                :placeholder="it.label"
                value-format="YYYY-MM-DD"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'datetime'"
                v-model="ruleForm[it.field]"
                type="datetime"
                :placeholder="it.label"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
              <el-radio-group
                v-model="ruleForm[it.field]"
                v-if="it.type == 'radio'"
              >
                <el-radio
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
              <el-radio-group
                v-model="ruleForm[it.field]"
                v-if="it.type == 'radio_img'"
              >
                <el-radio
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.value"
                  ><el-image :src="item.path" fit="fill"></el-image>
                </el-radio>
              </el-radio-group>
              <el-select
                clearable
                v-if="it.type == 'sel'"
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
                @change="it.change && it.change($event, it, callbackForm)"
              >
                <el-option
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="it.type == 'msel'"
                multiple
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
              >
                <el-option
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-switch
                v-if="it.type == 'switch'"
                v-model="ruleForm[it.field]"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
              <gps-upload
                v-if="it.type == 'upload'"
                :field="it.field"
                :imgPath="ruleForm[it.field]"
                @UploadEvent="UploadEvent"
              ></gps-upload> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancalFun()">{{ $t("commKey.Cancel") }}</el-button>
        <el-button type="primary" @click="submitForm()">
          {{ $t("commKey.Confirm") }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Watch } from "@element-plus/icons-vue";
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  defineEmits,
  toRaw,
  nextTick,
} from "vue";
import { useI18n } from "vue-i18n";

import GpsUpload from "../../../components/GpsUpload.vue";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  dialogWith: {
    type: Number,
    default: 70,
  },
  dialogTitle: {
    type: String,
    default: "",
  },
  formArray: {
    type: Array,
    default: () => {},
  },
  rowCount: {
    type: Number,
    default: 2,
  },
  row: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["saveEvent", "formEvent", "cancelEvent"]);

const dialogVisible = ref(false);
//请求数据

//表单控制
const ruleFormRef = ref(null);

let ruleFormObj = {};
let rulesObj = {};

const arr = [];
props.formArray.map((item, index) => {
  //赋值操作
  switch (item.type) {
    case "ipt_ipts":
      if (item.field) ruleFormObj[item.field] = item.val.split(",");
      break;
    default:
      if (item.field) ruleFormObj[item.field] = item.val;
      break;
  }
  //验证数据规则
  if (item.required) {
    rulesObj[item.field] = [
      {
        required: true,
        message: item.label + t("commKey.BITIAN"),
        trigger: "blur",
      },
    ];
  }

  // 计算该元素为第几个素组内;
  const itemIndex = Math.floor(index / props.rowCount);
  // 判断对应的索引是否存在
  if (!arr[itemIndex]) {
    arr[itemIndex] = [];
  }
  if (item.type != "") arr[itemIndex].push(item);
});

const ruleForm_temp = reactive(arr);

const ruleForm = ref(ruleFormObj);
const rules = reactive(rulesObj);
let formParaArrayData = {};
// const formParaArray = reactive(formParaArrayData);

const submitForm = async () => {
  // if (!formEl) return;
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      // console.log(ruleFormObj);toRaw
      // emit("saveEvent", ruleFormObj);
      // console.log(toRaw(ruleForm));
      emit("saveEvent", toRaw(ruleForm.value));
    } else {
      console.log("error submit!", fields);
    }
  });
};

const callbackForm = (obj) => {
  if (obj.toType) {
    let val = null;
    switch (obj.toType) {
      case "ipt_ipts":
        val = [];
        for (let i = 0; i < parseInt(obj.val); i++) {
          val.push("CH" + (i + 1));
        }
        break;
    }
    ruleForm[obj.toField] = val;
  } else {
    emit("formEvent", obj);
  }
};

watch(dialogVisible, (val) => {
  if (!val) {
    // ruleFormRef.resetFields();
  }
});
watch(
  () => props.row,
  (val) => {
    console.log("watch", val);
    nextTick(() => {
      ruleForm.value = val;
    });
  }
);
// const { text } = toRefs(props);
// const formatText = `Hi,${text && text.value}`;

///////////////////
const propoption = {
  icon: "icon",
  value: "gps_id",
  label: "nodeName",
  children: "children",
};

////////////////////////////////////////////
const UploadEvent = (obj) => {
  ruleForm[obj.field] = obj.img;
};

const cancalFun = () => {
  dialogVisible.value = false;
  emit("cancelEvent");
};

defineExpose({ dialogVisible });
</script>

<style lang="scss" scoped>
::v-deep(.el-select) {
  width: 100%;
}
::v-deep(label) {
  margin-bottom: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
</style>
