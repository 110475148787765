import { makeSign } from "./tools";
import store from "../store/index";

const time = Math.floor(new Date().getTime() / 1000);
const appId = Math.ceil(Math.random() * 100000);
const signObj = {
  time: time,
  app_id: appId,
};
const sign = makeSign(signObj);
signObj.sign = sign;
if (store.getters.token) {
  signObj.token = store.getters.token;
  // signObj["uid"] = store.getters.uid;
}

export default signObj;
